
.home {
    background: #fff;
    color: #6E7881;
    display: grid;
    align-items: center;
    justify-items: center;
}
.home .cursiva {
    font: 16px/24px 'librebaskerville-italic', serif;
}
.grid-padre{
   display: grid;
   grid-gap: 50px;
   padding-left: 25px;
   padding-right: 25px;
   font-size: var(--font-size-p);
}
.bcg{
   padding-top: 50px;
   padding-bottom: 50px;
background: var(--color1);
}
.grid-common{
   display: grid;
   grid-gap: 20px;
   max-width: 920px;
   align-items: center;
   justify-content: center;   
}
.grid{
   grid-template-columns: 120px 1fr;
}
.grid2{
   grid-template-columns: 1fr 120px ;
}
.home .info {
   grid-template-columns: repeat(1fr, 3fr);
    max-width: 1020px;
    margin-bottom: 18px;
    margin-top: 9px;
    text-align: center;
    padding-left: 25px;
    padding-right: 25px;
}

.home h1 {
    color: #11ABB0;
    font: 18px/24px 'opensans-bold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-bottom: 50px;
 }
 .home h1 span {
    border-bottom: 3px solid #11ABB0;
    padding-bottom: 6px;
 }

 /*
 #resume a, #resume a:visited  { color: #11ABB0; }
 #resume a:hover, #resume a:focus { color: #313131; }
 
 #resume h1 {
    font: 18px/24px 'opensans-bold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
 }
 #resume h1 span {
    border-bottom: 3px solid #11ABB0;
    padding-bottom: 6px;
 }
 #resume h3 {
    font: 25px/30px 'opensans-bold', sans-serif;
 }
 
 #resume .header-col { padding-top: 9px; }
 #resume .main-col { padding-right: 10%; }
 
 .education, .work {
    margin-bottom: 48px;
    padding-bottom: 24px;
    border-bottom: 1px solid #E8E8E8;
 }
 #resume .info {
    font: 16px/24px 'librebaskerville-italic', serif;
    color: #6E7881;
    margin-bottom: 18px;
    margin-top: 9px;
 }
 #resume .info span {
    margin-right: 5px;
    margin-left: 5px;
 }
 #resume .date {
    font: 15px/24px 'opensans-regular', sans-serif;
    margin-top: 6px;
 } */