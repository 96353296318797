#resume p{
   text-align: justify;
}
.classh1{
   text-align: center;
}
@media (min-width: 720px) {
   .classh1{
      text-align: justify;
   }
   
}