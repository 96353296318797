/*
=====================================================================
*   Ceevee v1.0 Layout Stylesheet
*   url: styleshout.com
*   03-18-2014
=====================================================================

   TOC:
   a. General Styles
   b. Header Styles
   c. About Section
   d. Resume Section
   e. Portfolio Section
   f. Call To Action Section
   g. Testimonials Section
   h. Contact Section
   i. Footer

===================================================================== */

/* ------------------------------------------------------------------ */

/* a. General Styles
/* ------------------------------------------------------------------ */

:root {
   --primary: #313131;
   --secondary: #11ABB0;
   --color1: #F9F9F9;
   --color2: #FFFFFF;
   --pane-padding: 5px 42px;
   --font-size-p: 18px;
}

body {
   background: var(--color1);
   font-family: "opensans-bold, sans-serif;";
}



.bold {
   font-weight: 700;
}

.color-secondary {
   color: var(--secondary);
}

.bloque {
   padding-top: 90px;
   padding-bottom: 52px;
   overflow: hidden;
}



/* ------------------------------------------------------------------ */

/* d. Resume Section
/* ------------------------------------------------------------------ */

#resume {
   background: #fff;
   padding-top: 90px;
   padding-bottom: 72px;
   overflow: hidden;
}

#resume a, #resume a:visited {
   color: #11ABB0;
}

#resume a:hover, #resume a:focus {
   color: #313131;
}

#resume h1 {
   font: 18px/24px 'opensans-bold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 1px;
}

#resume h1 span {
   border-bottom: 3px solid #11ABB0;
   padding-bottom: 6px;
}

#resume h3 {
   font: 25px/30px 'opensans-bold', sans-serif;
}

#resume .header-col {
   padding-top: 9px;
}

#resume .main-col {
   padding-right: 10%;
}

.education, .work {
   margin-bottom: 48px;
   padding-bottom: 24px;
   border-bottom: 1px solid #E8E8E8;
}

#resume .info {
   font: 16px/24px 'librebaskerville-italic', serif;
   color: #6E7881;
   margin-bottom: 18px;
   margin-top: 9px;
}

#resume .info span {
   margin-right: 5px;
   margin-left: 5px;
}

#resume .date {
   font: 15px/24px 'opensans-regular', sans-serif;
   margin-top: 6px;
}

/*----------------------------------------------*/

/*	Skill Bars
/*----------------------------------------------*/

.bars {
   width: 95%;
   float: left;
   padding: 0;
   text-align: left;
}

.bars .skills {
   margin-top: 36px;
   list-style: none;
}

.bars li {
   position: relative;
   margin-bottom: 60px;
   background: #ccc;
   height: 42px;
   border-radius: 3px;
}

.bars li em {
   font: 15px 'opensans-bold', sans-serif;
   color: #313131;
   text-transform: uppercase;
   letter-spacing: 2px;
   font-weight: normal;
   position: relative;
   top: -36px;
}

.bar-expand {
   position: absolute;
   left: 0;
   top: 0;
   margin: 0;
   padding-right: 24px;
   background: #313131;
   display: inline-block;
   height: 42px;
   line-height: 42px;
   border-radius: 3px 0 0 3px;
}

.photoshop {
   width: 60%;
   -moz-animation: photoshop 2s ease;
   -webkit-animation: photoshop 2s ease;
}

.illustrator {
   width: 55%;
   -moz-animation: illustrator 2s ease;
   -webkit-animation: illustrator 2s ease;
}

.wordpress {
   width: 50%;
   -moz-animation: wordpress 2s ease;
   -webkit-animation: wordpress 2s ease;
}

.css {
   width: 90%;
   -moz-animation: css 2s ease;
   -webkit-animation: css 2s ease;
}

.html5 {
   width: 80%;
   -moz-animation: html5 2s ease;
   -webkit-animation: html5 2s ease;
}

.jquery {
   width: 50%;
   -moz-animation: jquery 2s ease;
   -webkit-animation: jquery 2s ease;
}

@-moz-keyframes photoshop {
   0% {
      width: 0px;
   }
   100% {
      width: 60%;
   }
}

@-moz-keyframes illustrator {
   0% {
      width: 0px;
   }
   100% {
      width: 55%;
   }
}

@-moz-keyframes wordpress {
   0% {
      width: 0px;
   }
   100% {
      width: 50%;
   }
}

@-moz-keyframes css {
   0% {
      width: 0px;
   }
   100% {
      width: 90%;
   }
}

@-moz-keyframes html5 {
   0% {
      width: 0px;
   }
   100% {
      width: 80%;
   }
}

@-moz-keyframes jquery {
   0% {
      width: 0px;
   }
   100% {
      width: 50%;
   }
}

@-webkit-keyframes photoshop {
   0% {
      width: 0px;
   }
   100% {
      width: 60%;
   }
}

@-webkit-keyframes illustrator {
   0% {
      width: 0px;
   }
   100% {
      width: 55%;
   }
}

@-webkit-keyframes wordpress {
   0% {
      width: 0px;
   }
   100% {
      width: 50%;
   }
}

@-webkit-keyframes css {
   0% {
      width: 0px;
   }
   100% {
      width: 90%;
   }
}

@-webkit-keyframes html5 {
   0% {
      width: 0px;
   }
   100% {
      width: 80%;
   }
}

@-webkit-keyframes jquery {
   0% {
      width: 0px;
   }
   100% {
      width: 50%;
   }
}

/* ------------------------------------------------------------------ */

/* e. Portfolio Section
/* ------------------------------------------------------------------ */

#portfolio {
   background: #ebeeee;
   padding-top: 90px;
   padding-bottom: 60px;
}

#portfolio h1 {
   font: 15px/24px 'opensans-semibold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 1px;
   text-align: center;
   margin-bottom: 48px;
   color: #95A3A3;
}

/* Portfolio Content */

#portfolio-wrapper .columns {
   margin-bottom: 36px;
}

.portfolio-item .item-wrap {
   background: #fff;
   overflow: hidden;
   position: relative;
   -webkit-transition: all 0.3s ease-in-out;
   -moz-transition: all 0.3s ease-in-out;
   -o-transition: all 0.3s ease-in-out;
   -ms-transition: all 0.3s ease-in-out;
   transition: all 0.3s ease-in-out;
}

.portfolio-item .item-wrap a {
   display: block;
   cursor: pointer;
}

/* overlay */

.portfolio-item .item-wrap .overlay {
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   opacity: 0;
   -moz-opacity: 0;
   filter: alpha(opacity=0);
   -webkit-transition: opacity 0.3s ease-in-out;
   -moz-transition: opacity 0.3s ease-in-out;
   -o-transition: opacity 0.3s ease-in-out;
   transition: opacity 0.3s ease-in-out;
   /* background: url(../public/images/overlay-bg.png) repeat; */
}

.portfolio-item .item-wrap .link-icon {
   display: block;
   color: #fff;
   height: 30px;
   width: 30px;
   font-size: 18px;
   line-height: 30px;
   text-align: center;
   opacity: 0;
   -moz-opacity: 0;
   filter: alpha(opacity=0);
   -webkit-transition: opacity 0.3s ease-in-out;
   -moz-transition: opacity 0.3s ease-in-out;
   -o-transition: opacity 0.3s ease-in-out;
   transition: opacity 0.3s ease-in-out;
   position: absolute;
   top: 50%;
   left: 50%;
   margin-left: -15px;
   margin-top: -15px;
}

.portfolio-item .item-wrap img {
   vertical-align: bottom;
}

.portfolio-item .portfolio-item-meta {
   padding: 18px
}

.portfolio-item .portfolio-item-meta h5 {
   font: 14px/21px 'opensans-bold', sans-serif;
   color: #fff;
}

.portfolio-item .portfolio-item-meta p {
   font: 12px/18px 'opensans-light', sans-serif;
   color: #c6c7c7;
   margin-bottom: 0;
}

/* on hover */

.portfolio-item:hover .overlay {
   opacity: 1;
   -moz-opacity: 1;
   filter: alpha(opacity=100);
}

.portfolio-item:hover .link-icon {
   opacity: 1;
   -moz-opacity: 1;
   filter: alpha(opacity=100);
}

/* popup modal */

.popup-modal {
   max-width: 550px;
   background: #fff;
   position: relative;
   margin: 0 auto;
}

.popup-modal .description-box {
   padding: 12px 36px 18px 36px;
}

.popup-modal .description-box h4 {
   font: 15px/24px 'opensans-bold', sans-serif;
   margin-bottom: 12px;
   color: #111;
}

.popup-modal .description-box p {
   font: 14px/24px 'opensans-regular', sans-serif;
   color: #A1A1A1;
   margin-bottom: 12px;
}

.popup-modal .description-box .categories {
   font: 11px/21px 'opensans-light', sans-serif;
   color: #A1A1A1;
   text-transform: uppercase;
   letter-spacing: 2px;
   display: block;
   text-align: left;
}

.popup-modal .description-box .categories i {
   margin-right: 8px;
}

.popup-modal .link-box {
   padding: 18px 36px;
   background: #111;
   text-align: left;
}

.popup-modal .link-box a {
   color: #fff;
   font: 11px/21px 'opensans-bold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 3px;
   cursor: pointer;
}

.popup-modal a:hover {
   color: #00CCCC;
}

.popup-modal a.popup-modal-dismiss {
   margin-left: 24px;
}

/* fadein/fadeout effect for modal popup
/* ------------------------------------------------------------------ */

/* content at start */

.mfp-fade.mfp-wrap .mfp-content .popup-modal {
   opacity: 0;
   -webkit-transition: all 200ms ease-in-out;
   -moz-transition: all 200ms ease-in-out;
   -o-transition: all 200ms ease-in-out;
   -ms-transition: all 200ms ease-in-out;
   transition: all 200ms ease-in-out;
}

/* content fadein */

.mfp-fade.mfp-wrap.mfp-ready .mfp-content .popup-modal {
   opacity: 1;
}

/* content fadeout */

.mfp-fade.mfp-wrap.mfp-removing .mfp-content .popup-modal {
   opacity: 0;
}

/* ------------------------------------------------------------------ */

/* f. Call To Action Section
/* ------------------------------------------------------------------ */

#call-to-action {
   background: #212121;
   padding-top: 66px;
   padding-bottom: 48px;
}

#call-to-action h1 {
   font: 18px/24px 'opensans-bold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 3px;
   color: #fff;
}

#call-to-action h1 span {
   display: none;
}

#call-to-action .header-col h1:before {
   font-family: 'FontAwesome';
   content: "\f0ac";
   padding-right: 10px;
   font-size: 72px;
   line-height: 72px;
   text-align: left;
   float: left;
   color: #fff;
}

#call-to-action .action {
   margin-top: 12px;
}

#call-to-action h2 {
   font: 28px/36px 'opensans-bold', sans-serif;
   color: #EBEEEE;
   margin-bottom: 6px;
}

#call-to-action h2 a {
   color: inherit;
}

#call-to-action p {
   color: #636363;
   font-size: 17px;
}

/*#
call-to-action .button {
	color:#fff;
   background: #0D0D0D;
}
*/

#call-to-action .button:hover, #call-to-action .button:active {
   background: #FFFFFF;
   color: #0D0D0D;
}

#call-to-action p span {
   font-family: 'opensans-semibold', sans-serif;
   color: #D8D8D8;
}

/* ------------------------------------------------------------------
/* g. Testimonials
/* ------------------------------------------------------------------ */

#testimonials {
   /* background: #1F1F1F url(../public/images/testimonials-bg.jpg) no-repeat center center; */
   background-size: cover !important;
   -webkit-background-size: cover !important;
   background-attachment: fixed;
   position: relative;
   min-height: 200px;
   width: 100%;
   overflow: hidden;
}

#testimonials .text-container {
   padding-top: 96px;
   padding-bottom: 66px;
}

#testimonials h1 {
   font: 18px/24px 'opensans-bold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 3px;
   color: #fff;
}

#testimonials h1 span {
   display: none;
}

#testimonials .header-col {
   padding-top: 9px;
}

#testimonials .header-col h1:before {
   font-family: 'FontAwesome';
   content: "\f10d";
   padding-right: 10px;
   font-size: 72px;
   line-height: 72px;
   text-align: left;
   float: left;
   color: #fff;
}

/*	Blockquotes */

#testimonials blockquote {
   margin: 0 0px 30px 0px;
   padding-left: 0;
   position: relative;
   text-shadow: 0px 1px 3px rgba(0, 0, 0, 1);
}

#testimonials blockquote:before {
   content: none;
}

#testimonials blockquote p {
   font-family: 'librebaskerville-italic', serif;
   padding: 0;
   font-size: 24px;
   line-height: 48px;
   color: #fff
}

#testimonials blockquote cite {
   display: block;
   font-size: 12px;
   font-style: normal;
   line-height: 18px;
   color: #fff;
}

#testimonials blockquote cite:before {
   content: "\2014 \0020";
}

#testimonials blockquote cite a, #testimonials blockquote cite a:visited {
   color: #8B9798;
   border: none
}

/* Flex Slider
/* ------------------------------------------------------------------ */

/* Reset */

.flexslider a:active, .flexslider a:focus {
   outline: none;
}

.slides, .flex-control-nav, .flex-direction-nav {
   margin: 0;
   padding: 0;
   list-style: none;
}

.slides li {
   margin: 0;
   padding: 0;
}

/* Necessary Styles */

.flexslider {
   position: relative;
   zoom: 1;
   margin: 0;
   padding: 0;
}

.flexslider .slides {
   zoom: 1;
}

.flexslider .slides>li {
   position: relative;
}

/* Hide the slides before the JS is loaded. Avoids image jumping */

.flexslider .slides>li {
   display: none;
   -webkit-backface-visibility: hidden;
}

/* Suggested container for slide animation setups. Can replace this with your own */

.flex-container {
   zoom: 1;
   position: relative;
}

/* Clearfix for .slides */

.slides:before, .slides:after {
   content: " ";
   display: table;
}

.slides:after {
   clear: both;
}

/* No JavaScript Fallback */

/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */

.no-js .slides>li:first-child {
   display: block;
}

/* Slider Styles */

.slides {
   zoom: 1;
}

.slides>li {
   /*margin-right: 5px; */
   overflow: hidden;
}

/* Control Nav */

.flex-control-nav {
   width: 100%;
   position: absolute;
   bottom: -20px;
   text-align: left;
}

.flex-control-nav li {
   margin: 0 6px;
   display: inline-block;
   zoom: 1;
   *display: inline;
}

.flex-control-paging li a {
   width: 12px;
   height: 12px;
   display: block;
   background: #ddd;
   background: rgba(255, 255, 255, .3);
   cursor: pointer;
   text-indent: -9999px;
   -webkit-border-radius: 20px;
   -moz-border-radius: 20px;
   -o-border-radius: 20px;
   border-radius: 20px;
   box-shadow: inset 0 0 3px rgba(255, 255, 255, .3);
}

.flex-control-paging li a:hover {
   background: #CCC;
   background: rgba(255, 255, 255, .7);
}

.flex-control-paging li a.flex-active {
   background: #fff;
   background: rgba(255, 255, 255, .9);
   cursor: default;
}

/* ------------------------------------------------------------------ */

/* h. Contact Section
/* ------------------------------------------------------------------ */

#contact {
   background: #191919;
   padding-top: 96px;
   padding-bottom: 102px;
   color: #636363;
}

#contact .section-head {
   margin-bottom: 42px;
}

#contact a, #contact a:visited {
   color: #11ABB0;
}

#contact a:hover, #contact a:focus {
   color: #fff;
}

#contact h1 {
   font: 18px/24px 'opensans-bold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 3px;
   color: #EBEEEE;
   margin-bottom: 6px;
}

#contact h1 span {
   display: none;
}

#contact h1:before {
   font-family: 'FontAwesome';
   content: "\f0e0";
   padding-right: 10px;
   font-size: 72px;
   line-height: 72px;
   text-align: left;
   float: left;
   color: #ebeeee;
}

#contact h4 {
   font: 16px/24px 'opensans-bold', sans-serif;
   color: #EBEEEE;
   margin-bottom: 6px;
}

#contact p.lead {
   font: 18px/36px 'opensans-light', sans-serif;
   padding-right: 3%;
}

#contact .header-col {
   padding-top: 6px;
}

/* contact form */

#contact form {
   margin-bottom: 30px;
}

#contact label {
   font: 15px/24px 'opensans-bold', sans-serif;
   margin: 12px 0;
   color: #EBEEEE;
   display: inline-block;
   float: left;
   width: 26%;
}

#contact input, #contact textarea, #contact select {
   padding: 18px 20px;
   color: #eee;
   background: #373233;
   margin-bottom: 42px;
   border: 0;
   outline: none;
   font-size: 15px;
   line-height: 24px;
   width: 65%;
}

#contact input:focus, #contact textarea:focus, #contact select:focus {
   color: #fff;
   background-color: #11ABB0;
}

#contact button.submit {
   text-transform: uppercase;
   letter-spacing: 3px;
   color: #fff;
   background: #0D0D0D;
   border: none;
   cursor: pointer;
   height: auto;
   display: inline-block;
   border-radius: 3px;
   margin-left: 26%;
}

#contact button.submit:hover {
   color: #0D0D0D;
   background: #fff;
}

#contact span.required {
   color: #11ABB0;
   font-size: 13px;
}

#message-warning, #message-success {
   display: none;
   background: #0F0F0F;
   padding: 24px 24px;
   margin-bottom: 36px;
   width: 65%;
   margin-left: 26%;
}

#message-warning {
   color: #D72828;
}

#message-success {
   color: #11ABB0;
}

#message-warning i, #message-success i {
   margin-right: 10px;
}

#image-loader {
   display: none;
   position: relative;
   left: 18px;
   top: 17px;
}

/* Twitter Feed */

#twitter {
   margin-top: 12px;
   padding: 0;
}

#twitter li {
   margin: 6px 0px 12px 0;
   line-height: 30px;
}

#twitter li span {
   display: block;
}

#twitter li b a {
   font: 13px/36px 'opensans-regular', Sans-serif;
   color: #474747 !important;
   border: none;
}

/* ------------------------------------------------------------------ */

/* i. Footer
/* ------------------------------------------------------------------ */

footer {
   padding-top: 48px;
   margin-bottom: 48px;
   color: #303030;
   font-size: 14px;
   text-align: center;
   position: relative;
}

footer a, footer a:visited {
   color: #525252;
}

footer a:hover, footer a:focus {
   color: #fff;
}

/* copyright */

footer .copyright {
   margin: 0;
   padding: 0;
}

footer .copyright li {
   display: inline-block;
   margin: 0;
   padding: 0;
   line-height: 24px;
}

.ie footer .copyright li {
   display: inline;
}

footer .copyright li:before {
   content: "\2022";
   padding-left: 10px;
   padding-right: 10px;
   color: #095153;
}

footer .copyright li:first-child:before {
   display: none;
}

/* social links */

footer .social-links {
   margin: 18px 0 30px 0;
   padding: 0;
   font-size: 30px;
}

footer .social-links li {
   display: inline-block;
   margin: 0;
   padding: 0;
   margin-left: 42px;
   color: #F06000;
}

footer .social-links li:first-child {
   margin-left: 0;
}

/* Go To Top Button */

#go-top {
   position: absolute;
   top: -24px;
   left: 50%;
   margin-left: -30px;
}

#go-top a {
   text-decoration: none;
   border: 0 none;
   display: block;
   width: 60px;
   height: 60px;
   background-color: #525252;
   -webkit-transition: all 0.2s ease-in-out;
   -moz-transition: all 0.2s ease-in-out;
   -o-transition: all 0.2s ease-in-out;
   -ms-transition: all 0.2s ease-in-out;
   transition: all 0.2s ease-in-out;
   color: #fff;
   font-size: 21px;
   line-height: 60px;
   border-radius: 100%;
}

#go-top a:hover {
   background-color: #0F9095;
}