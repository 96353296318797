.biblio-grid{
   grid-template-columns:  100%;
   grid-template-rows: auto;

   grid-template-areas: 
    "text" 
    "imagen";
 }
 .biblio-grid2{
    grid-template-columns: 100%;
    grid-template-rows: auto;

    grid-template-areas: 
      "text2" 
      "imagen2"
      ;
 }

 .imagen {
   grid-area: imagen;
 }
 .imagen2 {
   grid-area: imagen2;
 }
 .text {
   grid-area: text;
 }
 .text2 {
   grid-area: text2;
 }

 @media (min-width: 720px) {
    .biblio-grid{
       grid-template-columns: 220px  1fr;
       grid-template-areas: 
       "imagen text";
     }
     .biblio-grid2{
        grid-template-columns: 1fr 220px;
        grid-template-areas: 
          "text2 imagen2";
     }
    
 }